<template>
  <v-dialog :max-width="370" v-model="show">
    <template v-slot:activator="{on}">
      <v-icon v-on="on" size="16" @click="open">mdi-pencil</v-icon>
    </template>
    <v-form :valid="valid" @submit.prevent="save" @keyup.enter="save" ref="form" lazy-validation>
      <v-card>
        <v-card-title class="py-6">
          <h6 class="text-h6 mx-auto">Edycja mapowania</h6>
        </v-card-title>
        <v-card-text>
          <v-text-field
            :rules="$rules.required"
            label="Słowo kluczowe"
            dense
            outlined
            v-model="form.keyword"
          />
          <AllCategoriesAutocomplete
            :rules="$rules.required"
            :category-name="category.name"
            v-model="form.categoryId"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn elevation="0" @click="close">Anuluj</v-btn>
          <v-btn color="primary" type="submit" :loading="pending">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AllCategoriesAutocomplete from '@/components/UI/Autocompletes/AllCategoriesAutocomplete'

export default {
  name: 'EditMappingModal',
  components: {AllCategoriesAutocomplete},
  props: {
    item: {
      type: Object,
      required: true,
    },
    pending: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      category: {},
      form: {
        mappingId: null,
        keyword: null,
        categoryId: null,
      },
      show: false,
      valid: true,
    }
  },
  watch: {
    show() {
      this.setForm()
    },
  },
  methods: {
    setForm() {
      const {id, name, category} = this.item
      this.form = {
        mappingId: id,
        keyword: name,
        categoryId: category.id,
      }
      Object.assign(this.category, category)
    },
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('formSubmitted', this.form)
        this.close()
      }
    },
  },
}
</script>

<style scoped></style>
